@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@300;400;500;700;800&display=swap");
* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  font-family: "Lexend", sans-serif;
}
body {
  font-family: "Lexend", sans-serif;
  letter-spacing: normal;
  padding: 0;
  margin: 0;
  font-weight: 400;
  line-height: normal;
  font-size: 16px;
  line-height: 18px;
  color: #222222;
}

img {
  max-width: 100%;
  border: 0;
  height: auto;
  outline: none;
  display: block;
}

a {
  text-decoration: none;
  border: 0;
  outline: none;
  cursor: pointer;
  transition: all 0.3s ease-in;
}

a:hover {
  opacity: 0.7;
  transition: all 0.3s ease-in;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

textarea {
  resize: none;
  overflow: auto;
}

input,
button,
textarea {
  -webkit-appearance: none;
  appearance: none;
  -moz-appearance: none;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-family: "Lexend", sans-serif;
}
.author-list-section
  .author-list-tabbing
  .author-list-tab-left
  .nav-tabs::-webkit-scrollbar,
.author-detail-page-secton
  .author-details-page-content
  .author-details-info
  .right-part
  .author-detail-page-tabbing
  .tab-content
  .trending-books-section
  .trending-books-content::-webkit-scrollbar,
.affiliate-account-section
  .stepper-nav-content
  .stepper-nav-content-item
  .form-details
  .web-and-mobile-step-info
  .input-field-group
  .added-url-list::-webkit-scrollbar,
.affiliate-account-section
  .stepper-nav-content
  .stepper-nav-content-item
  .form-details
  .profile-info::-webkit-scrollbar,
header
  .header-bottom
  .navbar-item
  li.dd-menu-wrapper
  .dd-menu::-webkit-scrollbar,
.book-detail-section
  .about-book-details
  .right-part
  .book-detail-page-tabbing
  .tab-content::-webkit-scrollbar,
.tearms-page
  .tab-items-wrapper
  .tab-item-content
  .terms-content
  .list::-webkit-scrollbar,
.authors-dashboard-section
  .author-tabbing-wrapper
  .author-tabbing-content
  .wallet-tab-content
  .wallet-items
  .column.balance-summary
  .summary
  ul::-webkit-scrollbar,
.dropdown.without-background .dropdown-menu::-webkit-scrollbar,
.read-book-section .read-book-body::-webkit-scrollbar,
.ck-editor-page-wrapper
  .editor-left-panel
  .editor-left-panel-content
  .add-upin-tab::-webkit-scrollbar,
.ck-editor-page-wrapper
  .editor-left-panel
  .editor-left-panel-content
  .add-asset-tab::-webkit-scrollbar,
.ck-editor-page-wrapper
  .editor-left-panel
  .editor-left-panel-content
  .add-image-format-tab::-webkit-scrollbar,
.ck-editor-page-wrapper
  .editor-left-panel
  .editor-left-panel-content
  .add-chapter-page-content::-webkit-scrollbar,
.ck-editor-page-wrapper
  .editor-left-panel
  .editor-left-panel-content
  .add-page-content::-webkit-scrollbar,
.ck-editor-page-wrapper .ck-editor-page-content-wrapper::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  border-radius: 4px;
  background-color: #eeeeee;
}
.author-list-section
  .author-list-tabbing
  .author-list-tab-left
  .nav-tabs::-webkit-scrollbar-thumb,
.author-detail-page-secton
  .author-details-page-content
  .author-details-info
  .right-part
  .author-detail-page-tabbing
  .tab-content
  .trending-books-section
  .trending-books-content::-webkit-scrollbar-thumb,
.affiliate-account-section
  .stepper-nav-content
  .stepper-nav-content-item
  .form-details
  .web-and-mobile-step-info
  .input-field-group
  .added-url-list::-webkit-scrollbar-thumb,
.affiliate-account-section
  .stepper-nav-content
  .stepper-nav-content-item
  .form-details
  .profile-info::-webkit-scrollbar-thumb,
header
  .header-bottom
  .navbar-item
  li.dd-menu-wrapper
  .dd-menu::-webkit-scrollbar-thumb,
.book-detail-section
  .about-book-details
  .right-part
  .book-detail-page-tabbing
  .tab-content::-webkit-scrollbar-thumb,
.tearms-page
  .tab-items-wrapper
  .tab-item-content
  .terms-content
  .list::-webkit-scrollbar-thumb,
.authors-dashboard-section
  .author-tabbing-wrapper
  .author-tabbing-content
  .wallet-tab-content
  .wallet-items
  .column.balance-summary
  .summary
  ul::-webkit-scrollbar-thumb,
.dropdown.without-background .dropdown-menu::-webkit-scrollbar-thumb,
.read-book-section .read-book-body::-webkit-scrollbar-thumb,
.ck-editor-page-wrapper
  .editor-left-panel
  .editor-left-panel-content
  .add-upin-tab::-webkit-scrollbar-thumb,
.ck-editor-page-wrapper
  .editor-left-panel
  .editor-left-panel-content
  .add-asset-tab::-webkit-scrollbar-thumb,
.ck-editor-page-wrapper
  .editor-left-panel
  .editor-left-panel-content
  .add-image-format-tab::-webkit-scrollbar-thumb,
.ck-editor-page-wrapper
  .editor-left-panel
  .editor-left-panel-content
  .add-chapter-page-content::-webkit-scrollbar-thumb,
.ck-editor-page-wrapper
  .editor-left-panel
  .editor-left-panel-content
  .add-page-content::-webkit-scrollbar-thumb,
.ck-editor-page-wrapper
  .ck-editor-page-content-wrapper::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: var(--primary-color);
}
:root {
  --primary-color: #8131d9;
  --body-color: #222222;
  --white-color: #ffffff;
  --green-color: #33b469;
  --light-grey: #666666;
  --dark-blue: #353794;
  --border-color: #cfcfcf;
  --font-family: "Lexend", sans-serif;
}
.white-color {
  color: var(--white-color) !important;
}
.primary-color {
  color: var(--primary-color) !important;
}
.green-color {
  color: var(--green-color) !important;
}
.light-grey {
  color: var(--light-grey);
}
.dark-blue {
  color: var(--dark-blue);
}
.danger-color {
  color: #ed3a3a !important;
}
.btn-danger {
  padding: 10px 16px !important;
  font-size: 14px !important;
  line-height: 16px !important;
  font-weight: 400 !important;
  border: 0 !important;
  background-color: #ed3a3a !important;
  color: var(--white-color) !important;
  text-align: center;
  font-family: var(--primary-font) !important;
  cursor: pointer !important;
  border-radius: 4px !important;
  border: 1px solid transparent !important;
  letter-spacing: 0 !important;
  transition: all 0.3s ease-in !important;
  width: 100% !important;
  height: 100% !important;
}
.btn-danger:hover {
  background-color: white !important;
  border-color: #ed3a3a !important;
  transition: all 0.3s ease-in !important;
  color: #ed3a3a !important;
}
.body-color {
  color: var(--body-color) !important;
}
.info-state {
  color: #2f80ed;
}
.fs-14 {
  font-size: 14px !important;
  line-height: 24px !important;
}
.fs-16 {
  font-size: 16px !important;
  line-height: 26px !important;
}
.fs-18 {
  font-size: 18px !important;
  line-height: 27px !important;
}
.fs-20 {
  font-size: 20px !important;
  line-height: 27px !important;
}
.fw-500 {
  font-weight: 500;
}

/* Mobile */
@mixin mobile {
  @media (min-width: 0px) and (max-width: 767px) {
    @content;
  }
}

/* Tablet */
@mixin tablet {
  @media (min-width: 0px) and (max-width: 992px) {
    @content;
  }
}

/* Small devices (landscape phones, 576px and up) */
@mixin min-576 {
  @media (min-width: 576px) {
    @content;
  }
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@mixin min-768 {
  @media (min-width: 768px) {
    @content;
  }
}

/* Large devices (desktops, 992px and up) */
@mixin min-992 {
  @media (min-width: 992px) {
    @content;
  }
}

@mixin min-1024 {
  @media (min-width: 1024px) {
    @content;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@mixin min-1200 {
  @media (min-width: 1200px) {
    @content;
  }
}

// small laptop screen
@mixin min-1340 {
  @media (min-width: 1340px) {
    @content;
  }
}

/* desktop devices (large desktops, 1200px and up) */
@mixin min-1440 {
  @media (min-width: 1440px) {
    @content;
  }
}

@mixin min-1600 {
  @media (min-width: 1600px) {
    @content;
  }
}

@mixin min-1800 {
  @media (min-width: 1800px) {
    @content;
  }
}

@mixin min-1920 {
  @media (min-width: 1920px) {
    @content;
  }
}

select,
input[type="submit"] {
  cursor: pointer;
}

button,
input,
select,
textarea,
form,
table {
  font-family: "SFRounded", sans-serif;
  outline: none;
}
label {
  display: inline-block;
  margin-bottom: 10px;
  text-transform: capitalize;
}
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
ul li {
  padding: 0;
  margin: 0;
  list-style: none;
}
/*placeholder color change */
input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: var(--light-grey);
}

input::-moz-placeholder {
  /* Firefox 19+ */
  color: var(--light-grey);
}

input:-ms-input-placeholder {
  /* IE 10+ */
  color: var(--light-grey);
}

textarea::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: var(--light-grey);
}

textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: var(--light-grey);
}

textarea:-ms-input-placeholder {
  /* IE 10+ */
  color: var(--light-grey);
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
.input-box {
  font-family: var(--primary-font);
  outline: none;
  border: 1px solid var(--border-color);
  color: var(--light-grey);
  padding: 14px;
  border-radius: 4px;
  width: 100%;
  &::placeholder {
    color: var(--light-grey);
    font-weight: 400;
  }
}
.label-input {
  position: relative;
  width: 100%;
  .input-box {
    padding-right: 70px;
  }
  .label {
    position: absolute;
    bottom: 12px;
    right: 14px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #2f80ed;
  }
}
form,
table {
  margin: 0;
  padding: 0;
  border: 0;
  border-radius: 0;
}
table {
  width: 100%;
  border-collapse: separate;
  border-radius: 0;
  border-spacing: 0;
}
.container-large {
  max-width: 1770px;
  padding: 0 20px;
  margin: 0 auto;
  box-sizing: border-box;
  @include min-768 {
    padding: 0 40px;
  }
  @include min-1200 {
    padding: 0 100px;
  }
  @include min-1920 {
    padding: 0;
  }
}
.container {
  max-width: 1580px;
  padding: 0 20px;
  margin: 0 auto;
  box-sizing: border-box;
  @include min-768 {
    padding: 0 40px;
  }
  @include min-1200 {
    padding: 0 140px;
  }
  @include min-1920 {
    padding: 0 20px;
  }
}
.btn,
button {
  padding: 10px 16px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  border: 0;
  background-color: var(--primary-color);
  color: var(--white-color);
  text-align: center;
  font-family: var(--primary-font);
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid transparent;
  letter-spacing: 0;
  transition: all 0.3s ease-in;
  @include min-768 {
    padding: 15px 25px;
    font-size: 16px;
  }
  @include min-1200 {
    padding: 20px 32px;
    font-size: 18px;
  }
}

.find-replace-btn {
  padding: 10px 16px !important;
  font-size: 14px !important;
  line-height: 16px !important;
  font-weight: 400 !important;
  border: 0 !important;
  background-color: var(--primary-color) !important;
  color: var(--white-color) !important;
  text-align: center;
  font-family: var(--primary-font) !important;
  cursor: pointer !important;
  border-radius: 4px !important;
  border: 1px solid transparent !important;
  letter-spacing: 0 !important;
  transition: all 0.3s ease-in !important;
  width: 100% !important;
  height: 100% !important;
}
.find-replace-btn:hover {
  background-color: white !important;
  border-color: var(--primary-color) !important;
  transition: all 0.3s ease-in !important;
  color: var(--primary-color) !important;
}
.btn.btn-primary {
  background-color: var(--primary-color);
  color: var(--white-color);
}
.btn.btn-outline-primary {
  color: var(--primary-color);
  background-color: var(--white-color);
  border-color: var(--primary-color);
}
.btn.btn-outline-primary:hover {
  background-color: var(--primary-color);
  color: var(--white-color);
}
.btn.btn-secondary {
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  background-color: var(--white-color);
}
.btn.btn-green {
  border: 1px solid var(--green-color);
  color: var(--white-color);
  background-color: var(--green-color);
  width: 100%;
  padding: 10px 16px;
  font-size: 14px;
  line-height: 16px;
  @include min-768 {
    padding: 15px 25px;
    font-size: 16px;
  }
  @include min-1200 {
    padding: 20px 32px;
    font-size: 18px;
  }
}
.btn.btn-white {
  border: 1px solid var(--white-color);
  color: var(--primary-color);
  background-color: var(--white-color);
}
.btn.btn-grey {
  border: 1px solid #cfcfcf;
  color: #666666;
  background-color: var(--white-color);
  font-weight: 500;
}
.btn.btn-dark-blue {
  border: 1px solid#8131D9;
  background-color: #8131d9;
  color: var(--white-color);
}
.btn:hover {
  background-color: transparent;
  border-color: var(--primary-color);
  transition: all 0.3s ease-in;
  color: var(--primary-color);
}
.btn.btn-secondary:hover {
  opacity: 0.6;
  transition: all 0.3s ease-in;
}
.btn.btn-white:hover {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  transition: all 0.3s ease-in;
  color: var(--white-color) !important;
}
.dropdown.primary {
  .dropdown-toggle-split {
    background-color: #6828ad;
    padding: 20px;
    &:after {
      content: none;
    }
  }
}
.dropdown.secondary {
  .btn-primary {
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    background-color: var(--white-color);
  }
  .dropdown-toggle-split {
    background-color: transparent;
    padding: 20px;
    border-left: 0;
    &:after {
      content: none;
    }
  }
}
.dropdown {
  .dropdown-menu {
    background: #ffffff;
    box-shadow: 0px 6px 23px #00000033;
    border-radius: 4px;
    border: 0;
    min-width: 200px;
    width: 100%;
    padding: 8px;
    .dropdown-item {
      font-weight: 500;
      font-size: 15px;
      line-height: 26px;
      border-radius: 4px;
      transition: all 0.3s ease-in;
      padding: 6px 8px;
      color: var(--body-color);
      &.active {
        background-color: transparent;
        color: var(--primary-color) !important;
      }
      &:hover,
      &.active:hover {
        background-color: #8131d91a;
      }
    }
  }
  &.without-background {
    .btn {
      background-color: transparent;
      padding: 0;
      border: 0;
      color: var(--dark-blue);
      &:after {
        content: none;
      }
    }
  }
}
.dropdown.without-background {
  display: flex;
  justify-content: space-between;
  .dropdown-menu {
    margin-top: 20px;
    margin-left: -14px;
    max-height: 400px;
    overflow: auto;
  }
  .btn {
    color: var(--light-grey);
    font-size: 16px;
    font-weight: 400;
    width: 100%;
    text-align: left;
    // svg {
    //   position: absolute;
    //   right: 20px;
    // }
  }
}
.dropdown .btn-secondary {
  padding: 15px 21px;
  border-color: var(--dark-blue);
  color: var(--dark-blue);
  &:after {
    content: none;
  }
}
.label-chip {
  padding: 2px 10px;
  background: var(--green-color);
  border-radius: 4px;
  display: inline-flex;
  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    color: var(--white-color);
    @include min-768 {
      font-size: 14px;
    }
  }
}

.count-label {
  position: absolute;
  top: -8px;
  right: 8px;
  background: var(--primary-color);
  border-radius: 50%;
  padding: 2px;
  min-width: 21px;
  min-height: 21px;
  text-align: center;
  color: white;
  font-size: 14px;
  @include tablet {
    font-size: 11px;
    min-width: 17px;
    min-height: 16px;
    padding: 0;
    right: -6px;
  }
}
.headng {
  .left-content {
    .fw-bold {
      font-size: 18px;
      @include min-768 {
        font-size: 24px;
      }
    }
    .white-color,
    .dark-blue {
      font-size: 14px;
      @include min-768 {
        font-size: 16px;
      }
    }
  }
}
.add-to-cart-book-box {
  background: var(--white-color);
  border-radius: 4px;
  padding: 14px;
  flex-wrap: wrap;
  @include min-1920 {
    min-width: 475px;
  }
  .add-cart-book-image {
    min-width: 150px;
    max-width: 150px;
    object-fit: cover;
    margin: 0 auto;
    @include min-1200 {
      margin: 0;
    }
  }
  .add-cart-book-data {
    margin-left: 0px;
    width: 100%;
    @include min-1200 {
      //    margin-left: 24px;
      width: calc(100% - 170px);
      margin-left: 0;
    }
    .title {
      h3 {
        font-weight: 700;
        font-size: 19px;
        line-height: 140%;
        color: var(--body-color);
        margin-bottom: 8px;
        margin-top: 15px;
      }
      p {
        color: var(--light-grey);
      }
    }
    .price {
      margin-top: 20px;
      @include min-768 {
        margin-top: 32px;
      }
      .price-number {
        font-size: 24px;
        line-height: 33px;
        color: var(--body-color);
        display: flex;
        align-items: center;
        span {
          color: var(--light-grey);
          font-size: 18px;
          text-decoration: line-through;
          margin-left: 5px;
        }
      }
      .rating {
        color: var(--light-grey);
        display: flex;
        font-size: 18px;
        align-items: center;
        svg {
          fill: #fdc12a;
          margin-right: 5px;
          font-size: 20px;
        }
      }
    }
    .btn-wrap {
      margin-top: 30px;
      .btn-add-to-cart {
        width: 100%;
        padding: 14px 28px;
        font-size: 16px;
        svg {
          margin-right: 7px;
          font-size: 17px;
          position: relative;
          top: -1px;
        }
      }
      .btn-green {
        svg {
          margin-right: 7px;
          font-size: 17px;
          position: relative;
          top: -1px;
        }
      }
    }
  }
}
/* carousel CSS */
.carousel-indicators {
  display: none;
}
// .carousel-control-next-icon {
//   background: url("../images/next-arrow.png") no-repeat;
//   background-size: cover;
// }
// .carousel-control-prev-icon {
//   background: url("../images/prev-arrow.png") no-repeat;
//   background-size: cover;
// }
.carousel-control-prev,
.carousel-control-next {
  bottom: 230px;
  top: auto;
}

/* accordion CSS */
.accordion {
  .accordion-item {
    border: 0;
    color: var(--body-color);
    .accordion-header {
      margin-bottom: 24px;
      .accordion-button {
        border: 0;
        padding: 0;
        background: transparent;
        box-shadow: none;
        color: var(--body-color);
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;
      }
    }
    .accordion-collapse {
      .accordion-body {
        padding: 0;
      }
    }
  }
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-icon);
}
/* Checbox and Radio button css */
.checkbox-group {
  .row {
    .form-check {
      margin-bottom: 18px !important;
    }
  }
  .form-check {
    margin-bottom: 18px;
    &:last-child {
      margin-bottom: 0;
    }
    .form-check-input {
      border-radius: 2px;
      border: 1px solid #babdcc;
      width: 18px;
      height: 18px;
      box-shadow: none;
      cursor: pointer;
      &:checked {
        background-color: var(--primary-color);
        border-color: var(--primary-color);
      }
    }
    .form-check-label {
      margin: 0;
      cursor: pointer;
      padding: 0;
      display: block;
      position: relative;
      top: 4px;
      margin-left: 3px;
      user-select: none;
    }
  }
  &.radio-button-group {
    .form-check {
      .form-check-input {
        border-radius: 50%;
        &:checked {
          + {
            .form-check-label {
              color: var(--primary-color);
            }
          }
        }
      }
    }
  }
}
.form-switch {
  &.switch-revers {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    padding-left: 0;
    max-width: 190px;
  }

  .form-check-input {
    border: 1px solid #babdcc;
    box-shadow: none;
    width: 42px;
    height: 24px;
    cursor: pointer;
    &:checked {
      background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e") !important;
      background-color: var(--primary-color);
      border-color: var(--primary-color);
    }
    &:focus {
      background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.25%29%27/%3e%3c/svg%3e");
    }
  }
  .form-check-label {
    margin: 0;
    cursor: pointer;
    padding: 0;
    display: block;
    position: relative;
    top: 7px;
    margin-left: 3px;
    user-select: none;
  }
}
.filter-label {
  background: #eeeeee;
  border-radius: 4px;
  padding: 10px 21px;
  span {
    font-weight: 500;
    color: #666666;
    margin-right: 12px;
  }
  a {
    color: var(--body-color);
    svg {
      font-size: 14px;
    }
  }
}
.corner-ribbon {
  width: 150px;
  background: var(--primary-color);
  position: absolute;
  top: 14px;
  left: -49px;
  text-align: center;
  line-height: 30px;
  letter-spacing: 1px;
  color: var(--white-color);
  font-weight: 700;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
.trending-corner-label {
  background: var(--primary-color);
  position: absolute;
  top: 0px;
  right: 0px;
  text-align: center;
  line-height: 30px;
  letter-spacing: 1px;
  color: var(--white-color);
  font-weight: 700;
  padding: 0 10px;
}
.breadcum {
  margin: 42px auto 52px auto;
  ul {
    li {
      font-size: 20px;
      line-height: 20px;
      a {
        color: var(--body-color);
        .line {
          margin: 0 10px;
          display: inline-block;
        }
      }
      &.active {
        a {
          color: #2f80ed;
        }
      }
    }
  }
}

.nav-tabs {
  border-bottom: 1px solid var(--border-color);
  .nav-item {
    .nav-link {
      padding: 5px 10px;
      border: 0;
      border-radius: 0;
      color: var(--light-grey);
      font-size: 16px;
      line-height: 24px;
      margin: 0;
      background: transparent;
      &.active {
        color: var(--body-color);
        border-bottom: 3px solid var(--primary-color);
      }
    }
  }
}
.progress {
  border-radius: 19px;
  height: 12px;
  .progress-bar {
    background-color: #2f80ed;
    border-radius: 19px;
  }
}
/* Loader design */
.loader-wrapper {
  min-height: 100px;
  margin-top: 100px;
}
.loader {
  position: absolute;
  top: 50%;
  left: 40%;
  margin-left: 10%;
  transform: translate3d(-50%, -50%, 0);
}
.dot {
  width: 24px;
  height: 24px;
  background: #3ac;
  border-radius: 100%;
  display: inline-block;
  animation: slide 1.3s infinite;
}
.dot:nth-child(1) {
  animation-delay: 0.1s;
  background: #6828ad;
}
.dot:nth-child(2) {
  animation-delay: 0.2s;
  background: #9569c6;
}
.dot:nth-child(3) {
  animation-delay: 0.3s;
  background: #b494d6;
}
.dot:nth-child(4) {
  animation-delay: 0.4s;
  background: #d2bfe6;
}
.dot:nth-child(5) {
  animation-delay: 0.5s;
  background: #f0eaf7;
}
@-moz-keyframes slide {
  0% {
    transform: scale(1);
  }
  50% {
    opacity: 0.3;
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
}
@-webkit-keyframes slide {
  0% {
    transform: scale(1);
  }
  50% {
    opacity: 0.3;
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
}
@-o-keyframes slide {
  0% {
    transform: scale(1);
  }
  50% {
    opacity: 0.3;
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes slide {
  0% {
    transform: scale(1);
  }
  50% {
    opacity: 0.3;
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
}

.video-play-button {
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  box-sizing: content-box;
  display: block;
  width: 32px;
  height: 44px;
  /* background: #fa183d; */
  border-radius: 50%;
  padding: 18px 20px 18px 28px;
  span {
    position: relative;
    z-index: 3;
    top: 0px;
    right: 8px;
    svg {
      font-size: 46px;
      color: #8131d9;
    }
  }
}

.video-play-button:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 70px;
  height: 70px;
  background: #582686;
  border-radius: 50%;
  -webkit-animation: pulse-border 1500ms ease-out infinite;
  animation: pulse-border 1500ms ease-out infinite;
}

.video-play-button:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 60px;
  height: 60px;
  background: white;
  border-radius: 50%;
  transition: all 200ms;
}

@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}

/* Ck editor css */
.ck-editor-header {
  background-color: #59179f;
  padding: 14px 24px;
  .header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .home {
      color: white;
      display: flex;
      align-items: center;
      width: 33.33%;
      svg {
        margin-right: 8px;
      }
    }
    .select-book-dropdown {
      width: 33.33%;
      .input-box {
        border: 0;
        padding: 0;
        color: white;
        .btn {
          color: white;
          max-width: 300px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
          white-space: nowrap;
        }
        .dropdown-menu {
          max-width: 300px;
        }
      }
    }

    .btn-wrap {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 33.33%;
      form {
        display: flex;
        align-items: center;
        p {
          margin-right: 8px;
          color: white;
          font-size: 14px;
        }
        .form-check-label {
          margin-left: 8px;
          color: white;
          font-size: 14px;
        }
      }
      .btn {
        margin-left: 32px;
        padding: 11px 20px;
        white-space: nowrap;
        font-size: 14px;
        min-width: 130px;
        max-width: 130px;
      }
    }
  }
}
.toolbar {
  border-bottom: 1px solid #cfcfcf;
  min-height: 54px;
  display: flex;
  justify-content: center;
  .toolbar-2 {
    margin: auto;
  }
}
.ck-editor-page-wrapper {
  display: flex;
  overflow: auto;
  justify-content: space-between;
  background: #f5f5f5;
  height: calc(117vh - 167px);
  @include min-1920 {
    height: calc(100vh - 167px);
  }
  overflow: auto;
  .editor-left-panel {
    min-width: 90px;
    .editor-left-panel-link {
      background-color: white;
      border-right: 1px solid #cfcfcf;
      .nav {
        .nav-item {
          background-color: white;
          &:first-child {
            .nav-link {
              &.active {
                border-top: 0;
              }
            }
          }
          .nav-link {
            background-color: white;
            padding: 25px 30px;
            border-right: 0;
            border-radius: 0;
            &.active {
              border-top: 1px solid #cfcfcf;
              border-bottom: 1px solid #cfcfcf;
              border-right: 0;
              width: calc(100% + 2px);
            }
          }
        }
      }
    }
    .editor-left-panel-content {
      width: 250px;
      min-width: 298px;
      z-index: 1;
      @include min-1200 {
        width: 333px;
      }
      .add-page-content {
        background-color: white;
        border-right: 1px solid #cfcfcf;
        height: calc(117vh - 167px);
        padding: 24px;

        ul {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          padding: 24px;
          @include min-1920 {
            height: calc(100vh - 167px);
          }
          overflow: auto;
          li {
            width: calc(50% - 10px);
            margin-bottom: 20px;
            img {
              width: 100%;
              cursor: pointer;
            }
          }
        }
        .btn-green {
          box-shadow: 0px 10px 20px rgba(65, 65, 65, 0.2);
          border-radius: 4px;
          padding: 10px 20px;
          font-size: 14px;
        }
      }
      .add-chapter-page-content {
        background-color: white;
        border-right: 1px solid #cfcfcf;
        padding: 24px;
        height: calc(117vh - 167px);
        @include min-1920 {
          height: calc(100vh - 167px);
        }
        overflow: auto;
        .btn-green {
          box-shadow: 0px 10px 20px rgba(65, 65, 65, 0.2);
          border-radius: 4px;
          padding: 10px 20px;
          font-size: 14px;
        }
        ul {
          li {
            border: 1px solid #cfcfcf;
            border-radius: 4px;
            background-color: white;
            margin-top: 20px;
            padding: 14px 18px;
            h6 {
              font-size: 18px;
              line-height: 22px;
            }
            span {
              font-size: 14px;
              line-height: 22px;
              margin: 12px 0 14px;
              color: #666666;
              display: block;
            }
            .label {
              background: rgba(47, 128, 237, 0.1);
              padding: 4px 10px;
              color: #2f80ed;
              font-size: 14px;
              line-height: 22px;
            }
            .btn-wrap {
              display: flex;
              justify-content: space-between;
              margin-top: 24px;
              .btn {
                width: calc(50% - 10px);
                padding: 5px 20px;
                font-size: 14px;
                line-height: 18px;
                background-color: white;
              }
              .btn-remove {
                color: #ed3a3a;
                border-color: #ed3a3a;
              }
              .btn-edit {
                color: #333333;
                border-color: #cfcfcf;
              }
            }
          }
        }
      }
      .add-image-format-tab {
        background-color: white;
        border-right: 1px solid #cfcfcf;
        padding: 24px;
        height: calc(117vh - 167px);
        @include min-1920 {
          height: calc(100vh - 167px);
        }
        overflow: auto;
        ul {
          li {
            margin-bottom: 20px;
            cursor: pointer;
          }
        }
      }
      .add-asset-tab {
        background-color: white;
        border-right: 1px solid #cfcfcf;
        padding: 24px;
        height: calc(117vh - 167px);
        @include min-1920 {
          height: calc(100vh - 167px);
        }
        overflow: auto;
        .btn-green {
          box-shadow: 0px 10px 20px rgba(65, 65, 65, 0.2);
          border-radius: 4px;
          padding: 10px 20px;
          font-size: 14px;
        }
        ul {
          margin-top: 24px;
          li {
            border: 1px solid #dadce0;
            border-radius: 4px;
            width: 100%;
            margin-bottom: 20px;
            img {
              max-height: 165px;
              object-fit: cover;
              width: 100%;
              border-radius: 4px 4px 0 0;
            }
            .asset-details {
              padding: 8px 12px;
              .label {
                width: 20px;
                height: 20px;
                color: white;
                background: #d12301;
                border-radius: 0px 4px;
                font-size: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 8px;
                &.green {
                  background: #007e35;
                }
                &.blue {
                  background: #009dce;
                }
              }
              h6 {
                font-size: 12px;
                line-height: 22px;
                color: rgba(102, 102, 102, 0.7);
                margin-top: 4px;
                span {
                  color: #666666;
                  font-weight: 500;
                }
              }
            }
          }
        }
      }
      .add-upin-tab {
        background-color: white;
        border-right: 1px solid #cfcfcf;
        padding: 24px;
        overflow: auto;
        height: calc(117vh - 167px);
        @include min-1920 {
          height: calc(100vh - 167px);
        }
        .btn-green {
          box-shadow: 0px 10px 20px rgba(65, 65, 65, 0.2);
          border-radius: 4px;
          padding: 10px 20px;
          font-size: 14px;
          margin-bottom: 24px;
        }
        ul {
          li {
            border: 1px solid #dadce0;
            border-radius: 4px;
            width: 100%;
            margin-bottom: 20px;
            padding: 14px;
            padding-bottom: 0;
          }
        }
      }
    }
  }
  .ck-editor-page-content-wrapper {
    max-width: 500px;
    min-width: 500px;
    height: auto;
    background: #ffffff;
    border: 1px solid #cfcfcf;
    width: calc(100% - 515px);
    margin: 20px 0;
    height: calc(117vh - 207px);
    @include min-1200 {
      max-width: 600px;
      min-width: 600px;
    }
    @include min-1920 {
      height: calc(100vh - 207px);
    }
    overflow: auto;
    .ck-editor-page-content {
      padding: 34px;
      h2 {
        font-size: 32px;
        line-height: 40px;
        margin-bottom: 6px;
      }
      p {
        font-size: 14px;
        line-height: 22px;
        color: #666666;
        margin-bottom: 12px;
      }
    }
  }
  .editor-right-panel {
    border-left: 1px solid #cfcfcf;
    background-color: white;
    ul {
      li {
        text-align: center;
        padding: 15px 25px;
        &:last-child {
          svg {
            font-size: 25px;
          }
        }
        svg {
          font-size: 20px;
          color: var(--primary-color);
        }
        span {
          font-size: 14px;
          margin-top: 6px;
          color: var(--light-grey);
          display: block;
        }
      }
    }
  }
}
.ck-editor-footer {
  border-top: 1px solid #cfcfcf;
  background-color: white;
  padding: 10px 110px;

  div {
    font-size: 14px;
    span {
      margin-left: 32px;
      margin-right: 8px;
    }
    a {
      color: #2f80ed;
      text-decoration: underline;
    }
  }
}
.btm-small {
  padding: 9px 10px !important;
  line-height: 8px !important;
}
.add-note-sidebar {
  &::after {
    content: "";
    background-color: rgba(0, 0, 0, 0.8);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 7;
    display: none;
    transition: all 0.4s ease-in;
  }
  &.show {
    &::after {
      display: block;
      transition: all 0.4s ease-in;
    }
    .sidebar-content {
      transition: all 0.8s ease-in;
      transition-timing-function: cubic-bezier(0.65, 0.3, 0.49, 0.92);
      right: 0;
    }
  }
  .sidebar-content {
    width: 400px;
    right: -400px;
    position: fixed;
    overflow-y: auto;
    overflow-x: hidden;
    bottom: 0;
    top: 0;
    z-index: 8;
    background-color: #ffffff;
    transition: all 0.8s ease-in;
    transition-timing-function: cubic-bezier(0.65, 0.3, 0.49, 0.92);
    @include mobile {
      width: 90%;
      right: -90%;
    }
    .heading {
      padding: 14px 16px;
      border-bottom: 1px solid #e3e3e3;
      a {
        margin-left: 16px;
      }
    }
    .sidebar-body {
      padding: 16px;
      .add-note {
        color: #2f80ed;
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 26px;
        display: flex;
        align-items: center;
        svg {
          font-size: 18px;
          margin-right: 10px;
        }
      }
      .add-note-box {
        background: #ffffff;
        border: 1px solid #cfcfcf;
        border-radius: 4px;
        padding: 10px;
        .add-note-heading {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 16px;
          p {
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: #666666;
            width: calc(100% - 60px);
          }
          .action {
            display: flex;
            a {
              font-size: 20px;
              margin-left: 8px;
              color: #666666;
              &:first-child {
                svg {
                  position: relative;
                  top: -2px;
                }
              }
            }
          }
        }
        .input-box {
          border: 0;
          padding: 0;
        }
        .desc {
          color: #666666;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          visibility: visible;
          overflow: hidden;
          font-weight: 400;
        }
        .btn-wrap {
          display: flex;
          justify-content: flex-end;
          a {
            color: var(--primary-color);
            font-weight: 500;
          }
        }
      }
      .added-note-list {
        margin-top: 32px;
        h4 {
          font-weight: 500;
          color: #4d4d4d;
          margin-bottom: 20px;
          font-size: 16px;
        }
        ul {
          li {
            margin-bottom: 16px;
          }
        }
      }
    }
  }
}

.add-task-sidebar {
  &::after {
    content: "";
    background-color: rgba(0, 0, 0, 0.8);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 7;
    display: none;
    transition: all 0.4s ease-in;
  }
  &.show {
    &::after {
      display: block;
      transition: all 0.4s ease-in;
    }
    .sidebar-content {
      transition: all 0.8s ease-in;
      transition-timing-function: cubic-bezier(0.65, 0.3, 0.49, 0.92);
      right: 0;
    }
  }
  .sidebar-content {
    width: 400px;
    right: -400px;
    position: fixed;
    overflow-y: auto;
    overflow-x: hidden;
    bottom: 0;
    top: 0;
    z-index: 8;
    background-color: #ffffff;
    transition: all 0.8s ease-in;
    transition-timing-function: cubic-bezier(0.65, 0.3, 0.49, 0.92);
    @include mobile {
      width: 90%;
      right: -90%;
    }
    .heading {
      padding: 14px 16px;
      border-bottom: 1px solid #e3e3e3;
      a {
        margin-left: 16px;
      }
    }
    .sidebar-body {
      padding: 16px;
      .add-note {
        color: #2f80ed;
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 26px;
        display: flex;
        align-items: center;
        svg {
          font-size: 18px;
          margin-right: 10px;
        }
      }
      .add-note-box {
        background: #ffffff;
        border: 1px solid #cfcfcf;
        border-radius: 4px;
        padding: 10px;
        .add-note-heading {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 16px;
          p {
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: #666666;
            width: calc(100% - 60px);
          }
          .action {
            display: flex;
            a {
              font-size: 20px;
              margin-left: 8px;
              color: #666666;
              &:first-child {
                svg {
                  position: relative;
                  top: -2px;
                }
              }
            }
          }
        }
        .input-box {
          border: 0;
          padding: 0;
        }
        .desc {
          color: #666666;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          visibility: visible;
          overflow: hidden;
          font-weight: 400;
        }
        .btn-wrap {
          display: flex;
          justify-content: flex-end;
          a {
            color: var(--primary-color);
            font-weight: 500;
          }
        }
      }
      .added-note-list {
        margin-top: 32px;
        h4 {
          font-weight: 500;
          color: #4d4d4d;
          margin-bottom: 20px;
          font-size: 16px;
        }
        ul {
          li {
            margin-bottom: 16px;
            .task-due-date {
              margin-top: 12px;
              .label {
                border: 0.5px solid #cfcfcf;
                border-radius: 42px;
                color: rgba(102, 102, 102, 0.85);
                font-weight: 400;
                font-size: 12px;
                line-height: 20px;
                padding: 2px 10px;
              }
            }
          }
        }
      }
    }
  }
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 10px;
  width: 10px;
  background: #8131d9;
  border-radius: 50%;
  border: 0;
  cursor: pointer;
}
.add-items-popup {
  position: absolute;
  right: 170px;
  top: 200px;
  table {
    border: 1px solid #cfcfcf;
    box-shadow: 1px 10px 20px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    background-color: white;
    tr {
      td {
        padding: 16px;
        border-right: 1px solid #e3e3e3;
        border-bottom: 1px solid #e3e3e3;
        min-width: 120px;
        padding: 16px 20px;
        cursor: pointer;
        &:hover {
          svg {
            color: var(--primary-color);
            transition: all 0.3s ease-in;
          }
          p {
            color: var(--primary-color);
            transition: all 0.3s ease-in;
          }
        }
        svg {
          font-size: 18px;
          display: block;
          margin: 0 auto;
          min-height: 19px;
          transition: all 0.3s ease-in;
        }
        p {
          font-size: 12px;
          line-height: 21px;
          text-align: center;
          margin-top: 6px;
          transition: all 0.3s ease-in;
        }
        &:last-child {
          border-right: 0;
        }
      }
      &:last-child {
        td {
          border-bottom: 0;
        }
      }
    }
  }
}
body {
  @include min-1340 {
    zoom: 85%;
  }
  @include min-1920 {
    zoom: 100%;
  }
}
.ql-snow .ql-picker.ql-lineheight {
  width: 58px;
}

.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value="1.0"]::before {
  content: "1.0";
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value="1.0"]::before {
  content: "1.0" !important;
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value="1.2"]::before {
  content: "1.2";
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value="1.2"]::before {
  content: "1.2" !important;
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value="1.5"]::before {
  content: "1.5";
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value="1.5"]::before {
  content: "1.5" !important;
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value="1.6"]::before {
  content: "1.6";
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value="1.6"]::before {
  content: "1.6" !important;
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value="1.8"]::before {
  content: "1.8";
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value="1.8"]::before {
  content: "1.8" !important;
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value="2.0"]::before {
  content: "2.0";
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value="2.0"]::before {
  content: "2.0" !important;
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value="2.4"]::before {
  content: "2.4";
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value="2.4"]::before {
  content: "2.4" !important;
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value="2.8"]::before {
  content: "2.8";
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value="2.8"]::before {
  content: "2.8" !important;
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value="3.0"]::before {
  content: "3.0";
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value="3.0"]::before {
  content: "3.0" !important;
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value="4.0"]::before {
  content: "4.0";
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value="4.0"]::before {
  content: "4.0" !important;
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value="5.0"]::before {
  content: "5.0";
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value="5.0"]::before {
  content: "5.0" !important;
}
.active-page {
  border: 2px solid var(--primary-color);
}
.modal-backdrop {
  height: 100%;
  width: 100%;
}
.error-text {
  color: #dc3545;
  font-size: 0.75rem;
  line-height: 1rem;
  margin: 0.25rem;
  text-transform: lowercase;
  display: block;
}
.dropContainer {
  margin: auto;
}
.dropzone {
  margin: auto;
}
.textContainer {
  width: 200;
  margin: auto;
  margin-top: 22px;
}
.rs-loader-backdrop {
  z-index: 14;
}
.rs-loader-spin {
  z-index: 15;
}
iframe {
  border: 0px;
}
.upin-svg {
  bottom: -2px;
  position: relative;
  margin-right: 2px !important;
}
